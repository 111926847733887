import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Form,
    FormGroup,
    TextInput
} from '@patternfly/react-core';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';
import { ValidatableInputField, ValidationOptions } from '../../ValidateableInputField';

export interface MainPanelProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}


class MainPanelImpl extends React.Component<MainPanelProps> {
    public state = {
        isLoading: true,
        saveStatus: undefined as (undefined | "success" | "saving"),
        minTopup: "",
        maxBalance: "",
        minTopupValid: false,
        maxBalanceValid: false,
    }

    componentDidMount() {
        this.props.settingsAPI.fetchDepositSettings().then(value => {
            const data = value.data!;
            this.setState({
                minTopup: data.minDeposit.toFixed(2),
                maxBalance: data.maxBalance.toFixed(2),
                isLoading: false
            });
        }).catch(error => {
            this.props.onAlert(`Could not load deposit settings: ${JSON.stringify(error)}`, "danger"); 
        });
    }

    onSave = () => {
        if(this.state.minTopupValid && this.state.maxBalanceValid) {
            this.setState({ saveStatus: "saving" });
            const info = {
                minDeposit: Number.parseFloat(this.state.minTopup),
                maxBalance: Number.parseFloat(this.state.maxBalance),
            };
            this.props.settingsAPI.postDepositSettings(info).then(value => {
                if (value.data) {
                    this.setState({ saveStatus: "success" });
                }
                else {
                    this.setState({ saveStatus: "failed" });
                }
            }).catch(error => {
                const msg = error.error.message ? error.error.message : "";
                this.props.onAlert(`Could not store deposit settings: ${msg}`, "danger");
                this.setState({ saveStatus: "failed" });
            });
        }
    }

    render() {
        const { t } = this.props;
        const { saveStatus, minTopup, maxBalance, isLoading, minTopupValid, maxBalanceValid } = this.state;

        return (
            <SaveablePanel
                title="Allgemein"
                saveStatus={saveStatus}
                isLoading={isLoading}
                onSave={this.onSave}
                saveDisabled={!minTopupValid || !maxBalanceValid}
            >
                <Form isHorizontal
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSave();
                    }}
                >
                    <ValidatableInputField
                        inputId='minTopup'
                        title='Mindestaufladebetrag:'
                        validateFor={ValidationOptions.TwoDigitFixedPoint}
                        value={minTopup}
                        onChange={(value, valid) => {
                            this.setState({ minTopup: value, minTopupValid: valid })
                        }}
                    />
                    <ValidatableInputField
                        inputId='maxBalance'
                        title='Maximalkontostand:'
                        validateFor={ValidationOptions.TwoDigitFixedPoint}
                        value={maxBalance}
                        onChange={(value, valid) => {
                            this.setState({ maxBalance: value, maxBalanceValid: valid })
                        }}
                    />
                </Form>
            </SaveablePanel>
        );
    }
}

export const MainPanel = withTranslation()(MainPanelImpl);