import { FormGroup, TextInput, FormHelperText, HelperText, HelperTextItem } from "@patternfly/react-core";
import { right } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { ExclamationCircleIcon } from "@patternfly/react-icons";
import { string } from "prop-types";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";


export enum ValidationOptions {
    None = 0,
    LengthMoreThan0 = 1,
    Email = 2,
    Number = 3,
    TwoDigitFixedPoint = 4,  // for currency
    Password = 5,
    RFIDRegisterCode = 6
}

export interface ValidatableInputFieldProps extends WithTranslation {
    title: string,
    value: string,
    validateFor: ValidationOptions
    onChange: (value: string, valid: boolean) => void,
    inputId: string,
    isDisabled?: boolean,
    withoutFormGroup?: boolean,
    extraIcon?: React.ReactElement,
    onTextChangeRaw?: (_event, text) => void,
}

class ValidatableInputFieldImpl extends React.Component<ValidatableInputFieldProps> {
    public state = {
        isValid: undefined as (undefined | "error"),
        fieldValue: "",
        errorMessage: "",
    }

    validateField(input: string) {
        let valid: boolean = true;
        const emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const twoDigitRe = /^-?\d+(?:[.,]\d?\d?)?$/

        switch (this.props.validateFor) {
            case ValidationOptions.LengthMoreThan0:
                valid = input.length > 0;
                break;
            case ValidationOptions.Email:
                valid = emailRe.test(input);
                break;
            case ValidationOptions.Number:
                valid = !isNaN(input) && !isNaN(parseFloat(input));
                break;
            case ValidationOptions.TwoDigitFixedPoint:
                valid = twoDigitRe.test(input);
                break
            case ValidationOptions.Password:
                valid = input.length >= 6;
                break;
            case ValidationOptions.RFIDRegisterCode:
                valid = !isNaN(input) && !isNaN(parseFloat(input)) && input.length == 3
                break;
            default:
                break;
        }

        this.setState({ fieldValue: input, isValid: valid ? undefined : "error" })
        this.props.onChange(input, valid);
        return valid;
    }

    componentDidMount(): void {
        let errorMessage = ""
        switch (this.props.validateFor) {
            case ValidationOptions.LengthMoreThan0:
                errorMessage = this.props.t("inputfields:errors.empty");
                break;
            case ValidationOptions.Email:
                errorMessage = this.props.t("inputfields:errors.email");
                break;
            case ValidationOptions.Number:
                errorMessage = this.props.t("inputfields:errors.number");
                break;
            case ValidationOptions.TwoDigitFixedPoint:
                errorMessage = this.props.t("inputfields:errors.fixed");
                break
            case ValidationOptions.Password:
                errorMessage = this.props.t("inputfields:errors.password");
                break;
            case ValidationOptions.RFIDRegisterCode:
                errorMessage = this.props.t("inputfields:errors.rfidRegisterCode");
                break;
            default:
                break;
        }

        this.validateField(this.props.value);
        this.setState({ errorMessage: errorMessage});
    }
    
    textFieldStyle(): React.CSSProperties {
        if (this.props.validateFor == ValidationOptions.Number || 
            this.props.validateFor == ValidationOptions.TwoDigitFixedPoint)
            return {textAlign: "right"}
        else
            return {textAlign: "left"}
    }

    render(): React.ReactNode {
        const { isValid, fieldValue, errorMessage } = this.state;
        const { title, inputId, isDisabled, extraIcon } = this.props;

        const content = (
            <React.Fragment>
            <TextInput
                style={this.textFieldStyle()}
                value={fieldValue}
                validated={isValid}
                id={inputId}
                onChange={(_event, value) => {
                    this.validateField(value);
                    this.props.onTextChangeRaw?.(_event, value);
                }}
                isDisabled={isDisabled}
                customIcon={extraIcon}
            />
            <FormHelperText hidden={isValid !== "error"}>
                <HelperText>
                    <HelperTextItem variant={isValid} {...(isValid === 'error' && { icon: <ExclamationCircleIcon />})}>
                        {errorMessage}
                    </HelperTextItem>
                </HelperText>
            </FormHelperText>
            </React.Fragment>
        )

        if (this.props.withoutFormGroup) {
            return content;
        } else {
            return (
                <React.Fragment>
                    <FormGroup
                        label={title}
                        fieldId={inputId}
                    >
                        {content}
                    </FormGroup>
                </React.Fragment>
            )
        }
    }
}

export const ValidatableInputField = withTranslation()(ValidatableInputFieldImpl);