import { RemoteConnection, encodeQueryData } from "@app/RemoteConnection";
import { TableFilterQuery } from "@app/model/IDBHelpers";
import {
    ISettingsAPI, IDepositSettings, IStripeSettings, IPayPalSettings,
    IEmailTemplate, IOutdateTimeoutConfig, IAPIKey
} from
    "@app/model/ISettingsAPI";

export class SettingsAPI implements ISettingsAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    fetchDepositSettings() {
        return this._connection.getPromise<IDepositSettings, void>("deposit/settings");
    }

    postDepositSettings(data: IDepositSettings) {
        return this._connection.postPromise<boolean, void>("deposit/settings", data);
    }

    fetchStripeSettings() {
        return this._connection.getPromise<IStripeSettings, void>("stripe/settings");
    }

    postStripeSettings(data: IStripeSettings) {
        return this._connection.postPromise<boolean, void>("stripe/settings", data);
    }

    fetchPayPalSettings() {
        return this._connection.getPromise<IPayPalSettings, void>("paypal/settings");
    }

    postPayPalSettings(data: IPayPalSettings) {
        return this._connection.postPromise<boolean, void>("paypal/settings", data);
    }

    fetchAPIKeys(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
       return this._connection.getPromise<IAPIKey[], void>(`api_keys/keys?${args}`);
    }

    postDeleteAPIKey(id: number) {
        return this._connection.postPromise<boolean, void>("api_keys/delete", id);
    }

    postCreateAPIKey(data: IAPIKey){
        return this._connection.postPromise<boolean, void>("api_keys/create", data);
    }

    fetchTimeoutSettings() {
       return this._connection.getPromise<IOutdateTimeoutConfig, void>("timeout/settings");
    }

    postTimeoutSettings(data: IOutdateTimeoutConfig) {
        return this._connection.postPromise<boolean, void>("timeout/settings", data);
    }


    fetchEmailTemplate(name: string) {
       return this._connection.getPromise<IEmailTemplate, void>("template/" + name);
    }
 
    postEmailTemplate(name: string, value: IEmailTemplate) {
        return this._connection.postPromise<boolean, void>("template/" + name, value);
    }
}