import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Alert,
    Bullseye,
    EmptyState,
    EmptyStateBody,
    EmptyStateIcon,
    Form,
    FormGroup,
    Modal,
    ModalVariant,
    TextInput,
    Title,
    Wizard,
    WizardHeader,
    WizardStep,

} from '@patternfly/react-core';

import SuccessIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';
import { ISignupData, IUserAPI } from '@app/model/IUserAPI';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';

export interface RegisterWizardProps extends WithTranslation {
    isOpen: boolean,
    onClose: () => void,
    userAPI: IUserAPI
}

class RegisterWizardImpl extends React.Component<RegisterWizardProps> {
    public state = {
        // Step 1
        rfidRegisterCode: "",
        rfidRegisterCodeValid: false,

        // Step 2
        firstname: "",
        firstnameValid: false,
        lastname: "",
        lastnameValid: false,
        email: "",
        emailValid: false,

        registerError: "",
        triggerNextStep: false,
    }

    registerAccount = () => {
        const data = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            rfidRegisterCode: this.state.rfidRegisterCode,
            email: this.state.email
        } as ISignupData;

        this.props.userAPI.postSignup(data).then(result => {
            if (result.error) {
                let message = "";
                switch (result.error) {
                    case "emailExists":
                    case "codeInvalid":
                    case "nameInvalid":
                    case "emailInvalid":
                        message = this.props.t("berta:register.errors." + result.error)
                        break;
                    default:
                        message = result.error;
                        break;
                }
                this.setState({registerError: message});
            }
            else if (result.data?.ok) {
                this.setState({registerError: "", triggerNextStep: true});
            }
            else {
                this.setState({registerError: this.props.t("berta:register.errors.internalError")});
            }
        }).catch(error => {
            this.setState({registerError: this.props.t("berta:restResponses.error") + JSON.stringify(error)});
        });
    }

    validateStep = (activeStep, onNext) => {
        if (activeStep.name == 'Startcode') {
            
        }
        else if (activeStep.name == 'Personal Data') {
            let allOK = this.state.firstnameValid && this.state.lastnameValid && this.state.emailValid;

            if (allOK)
                onNext();
        }
    }

    render() {
        const { rfidRegisterCode, rfidRegisterCodeValid, firstname, firstnameValid, triggerNextStep,
            lastname, lastnameValid, email, emailValid, registerError } = this.state;
        const { t } = this.props;

        const startcodeComponent = (
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <ValidatableInputField
                    title={t("inputfields:startcode")}
                    inputId='rfid'
                    validateFor={ValidationOptions.RFIDRegisterCode}
                    onChange={(value, valid) => {
                        this.setState({ rfidRegisterCode: value, rfidRegisterCodeValid: valid })
                    }}
                    value={rfidRegisterCode}
                />
            </Form>
        );

        const dataComponent = (
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <ValidatableInputField
                    title={t("inputfields:firstname")}
                    inputId="firstname"
                    validateFor={ValidationOptions.LengthMoreThan0}
                    onChange={(value, valid) => {
                        this.setState({ firstname: value, firstnameValid: valid })
                    }}
                    value={firstname}
                />
                <ValidatableInputField
                    title={t("inputfields:lastname")}
                    inputId="lastname"
                    validateFor={ValidationOptions.LengthMoreThan0}
                    onChange={(value, valid) => {
                        this.setState({ lastname: value, lastnameValid: valid })
                    }}
                    value={lastname}
                />
                <ValidatableInputField
                    title={t("inputfields:email")}
                    inputId="email"
                    validateFor={ValidationOptions.Email}
                    onChange={(value, valid) => {
                        this.setState({ email: value, emailValid: valid })
                    }}
                    value={email}
                />
            </Form>
        );

        let alertComponent = undefined as (JSX.Element | undefined);
        if (registerError) {
            alertComponent = (<Alert variant="danger" title={registerError} style={{marginBottom: "1.5em"}} />);
        }

        const checkComponent = (
            <React.Fragment>
                {alertComponent}
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <FormGroup
                        label={t("inputfields:startcode")}
                        fieldId="startcode"
                    >
                        <TextInput
                            value={rfidRegisterCode}
                            id="startcode"
                            aria-describedby="startcode-helper"
                            isDisabled
                        />
                    </FormGroup>
                    <FormGroup
                        label={t("inputfields:firstname")}
                        fieldId="firstname"
                    >
                        <TextInput
                            value={firstname}
                            id="firstname"
                            aria-describedby="firstname-helper"
                            isDisabled
                        />
                    </FormGroup>
                    <FormGroup
                        label={t("inputfields:lastname")}
                        fieldId="lastname"
                    >
                        <TextInput
                            value={lastname}
                            id="lastname"
                            aria-describedby="lastname-helper"
                            isDisabled
                        />
                    </FormGroup>
                    <FormGroup
                        label={t("inputfields:email")}
                        fieldId="email"
                    >
                        <TextInput
                            value={email}
                            id="email"
                            type="email"
                            aria-describedby="email-helper"
                            isDisabled
                        />
                    </FormGroup>
                </Form>
            </React.Fragment>
        );

        const finishComponent = (
            <Bullseye>
                <EmptyState variant="lg">
                    <EmptyStateIcon icon={SuccessIcon} />
                    <Title headingLevel="h4" size="lg">
                        {t("berta:register.success")}
                    </Title>
                    <EmptyStateBody>
                        {t("berta:register.successNote")}
                    </EmptyStateBody>
                </EmptyState>
            </Bullseye>
        );


        const wizardHeader = (
            <WizardHeader
                title={t("berta:register.wizardTitle")}
                description={t("berta:register.wizardSubtitle")}
                onClose={this.props.onClose}
            />
        )

        return (
            <Modal
                isOpen={this.props.isOpen}
                showClose={false}
                hasNoBodyWrapper
                aria-label='register-modal'
                onEscapePress={() => {this.props.onClose()}}
                variant={ModalVariant.large}
            >
                <Wizard
                    header={wizardHeader}
                    onClose={this.props.onClose}
                >
                    <WizardStep 
                        name={t("berta:register.steps.1")} 
                        id="stp1" 
                        footer={{isNextDisabled: !rfidRegisterCodeValid}}
                    >
                        {startcodeComponent}
                    </WizardStep>
                    <WizardStep 
                        name={t("berta:register.steps.2")}
                        id="stp2" 
                        footer={{
                            isNextDisabled: !(firstnameValid && lastnameValid && emailValid),
                        }}
                    >
                        {dataComponent}
                    </WizardStep>
                    <WizardStep
                        name={t("berta:register.steps.3")}
                        id="stp3"
                        footer={{ 
                            nextButtonText: 'Finish', 
                            onNext: () => { this.registerAccount() } 
                        }}
                    >
                        {checkComponent}
                    </WizardStep>
                    <WizardStep
                        name={t("berta:register.steps.4")}
                        id="stp4"
                        footer={{ nextButtonText: 'Close', onNext: this.props.onClose }}
                    >
                        {finishComponent}
                    </WizardStep>
                </Wizard>
            </Modal>
        );
    }
}

export const RegisterWizard = withTranslation()(RegisterWizardImpl);