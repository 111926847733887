import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    PageSection,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Grid,
    GridItem,
    DescriptionList,
    DescriptionListGroup,
    DescriptionListTerm,
    DescriptionListDescription,
    Divider,
    Flex,
    FlexItem
} from '@patternfly/react-core';

import { BuyCard } from './MainPage/BuyCard';
import { TopupDialog } from './MainPage/TopupDialog';
import { Link, matchPath } from 'react-router-dom';
import { withRouter, WithRouter } from '@app/RouterComponent';
import { BuyCountEntry, IStatsAPI } from '@app/model/IStatsAPI';
import { IDepositAPI } from '@app/model/IDepositAPI';
import { ITransactionAPI } from '@app/model/ITransactionAPI';
import { TransactionTable } from './MainPage/TransactionTable';
import { User } from '@app/api/UserAPI';

export interface MainPageProps extends WithTranslation, WithRouter {
    currentUser?: User,
    statsAPI: IStatsAPI,
    depositAPI: IDepositAPI,
    transferAPI: ITransactionAPI,
    onAlert: (title: string, variant: string) => void,
}

class MainPageImpl extends React.Component<MainPageProps> {

    public state = {
        buyCardData: undefined as (undefined | BuyCountEntry[]),
        buyCardLoading: true
    }

    onBuyCardDurationChange = (duration: string) => {
        let from = new Date();
        switch (duration) {
            case "week":
                from.setDate(from.getDate() - (from.getDay() + 6) % 7);
                from.setHours(0, 0, 0, 0);
                break;
            case "month":
                from = new Date(from.getFullYear(), from.getMonth(), 1);
                break;
            case "year":
                from = new Date(from.getFullYear(), 0, 1);
                break;
            default:
                from.setTime(0);
        }
        
        this.props.statsAPI.fetchBuyCount(this.props.currentUser!.id, from).then(result => {
            const data = result.data!.sort((a, b) => { return b.count - a.count }).slice(0, 10);
            this.setState({ buyCardData: data, buyCardLoading: false });
        }).catch(error => {
            this.props.onAlert(`Could not load buy stats: ${JSON.stringify(error)}`, "danger");
            this.setState({ buyCardData: [] });
        });
    }

    render() {
        const { location, navigate, currentUser, depositAPI, onAlert, transferAPI, t } = this.props;
        const { buyCardData, buyCardLoading } = this.state;
        const topupOpen = matchPath("/top-up", location.pathname) ? true : false;

        if (topupOpen)
            document.title = t("berta:topup.pageTitle");
        else
            document.title = t("berta:main.pageTitle");

        // Intial load once user data is available
        if (currentUser && buyCardData == undefined) {
            this.onBuyCardDurationChange("");
        }

        return (
            <React.Fragment>
                <TopupDialog isOpen={topupOpen} onClose={() => navigate("/")}
                    onAlert={onAlert}
                    depositAPI={depositAPI}
                    currentBalance={currentUser ? currentUser.balance : 0} />
                <PageSection isFilled>
                    <Grid hasGutter>
                        <GridItem span={8} rowSpan={6}>
                            <Card
                                isCompact
                            >
                                <CardHeader />
                                <CardTitle>{t("berta:transactions.panelTitle")}</CardTitle>
                                <CardBody>
                                    {currentUser &&
                                        <TransactionTable onAlert={onAlert} transferAPI={transferAPI} 
                                            uid={currentUser.id} fetchOnMount={true}/>}
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem span={4} rowSpan={2}>
                            <Flex direction={{ default: 'column' }}>
                                <FlexItem>
                                    <Card>
                                        <CardTitle>{t("berta:main.account")}</CardTitle>
                                        <CardBody>
                                            <DescriptionList>
                                                <DescriptionListGroup>
                                                    <DescriptionListTerm>{t("berta:main.balance")}</DescriptionListTerm>
                                                    <DescriptionListDescription>
                                                        {currentUser ? currentUser.balance.toFixed(2) + ' €' : ""}
                                                    </DescriptionListDescription>
                                                </DescriptionListGroup>
                                            </DescriptionList>
                                        </CardBody>
                                        <Divider />
                                        <CardFooter>
                                            <Link to="/top-up">{t("berta:main.topUp")}</Link>
                                        </CardFooter>
                                    </Card>
                                </FlexItem>
                                <FlexItem>
                                    <BuyCard products={buyCardData} isLoading={buyCardLoading} onDurationChange={(duration) => {
                                            this.setState({ buyCardLoading: true });
                                            this.onBuyCardDurationChange(duration);
                                    }} />
                                </FlexItem>
                            </Flex>
                        </GridItem>
                    </Grid>
                </PageSection>
            </React.Fragment>
        );
    }
}

export const MainPage = withTranslation()(withRouter(MainPageImpl));