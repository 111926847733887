import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Modal,
    ModalVariant,
    TextContent,
    Text,
    TextVariants,
    ActionGroup,
    Button,
    Form,
    FormGroup,
    Select,
    SelectOption,
    TextInput,
    MenuToggle,
    Alert,
} from '@patternfly/react-core';
import { DepositLimits, PaymentMethod, IDepositAPI } from '@app/model/IDepositAPI';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { TintSlashIcon } from '@patternfly/react-icons';

export interface TopupDialogProps extends WithTranslation {
    isOpen: boolean,
    currentBalance: number,
    onClose: () => void,
    depositAPI: IDepositAPI,
    onAlert: (title: string, variant: string) => void
}

class TopupDialogImpl extends React.Component<TopupDialogProps> {
    public state = {
        depositLimits: {minDeposit: 0, maxBalance: 0} as DepositLimits,

        topupFee: 0,
        topupFieldValid: false,
        topupAmountActual: 0,
        topupAmountRequested: "5.00",
        topupAmountRequestedNum: 5.00,
        balanceNew: 0,

        topupMethods: [] as PaymentMethod[],
        topupMethodSelected: undefined as (undefined | PaymentMethod),
        topupMethodSelectionOpen: false,

        validated: false,
        errorMessage: "Loading data ...",
        buttonLoading: false,
        isLoading: true,
    }

    componentDidMount() {
        const { depositAPI, onAlert } = this.props;

        depositAPI.fetchLimits().then(limits => {
            depositAPI.fetchMethods().then(methods => {
                this.setState({
                    depositLimits: limits.data,
                    topupMethods: methods.data,
                    topupAmountRequested: limits.data!.minDeposit.toFixed(2),
                    topupMethodSelected: methods.data![0],
                    errorMessage: "",
                    isLoading: false,
                });
                this.checkValueAndComputeFee();
            }).catch(error => {
                onAlert(`Could not load payment methods: ${JSON.stringify(error)}`, "danger");
            });
        }).catch(error => {
            onAlert(`Could not load deposit limits: ${JSON.stringify(error)}`, "danger");
        });
    }

    componentDidUpdate(prevProps: Readonly<TopupDialogProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevState.topupAmountRequested != this.state.topupAmountRequested ||
           prevState.isLoading != this.state.isLoading){
            this.checkValueAndComputeFee();
        }
    }

    // Takes the requested topup amount from the state and computes the fee and whether the 
    // requested amount is within the set limits
    checkValueAndComputeFee = () => {
        const { currentBalance, t } = this.props;
        const { minDeposit, maxBalance  } = this.state.depositLimits;
        const { topupMethodSelected, topupAmountRequested } = this.state;

        if (topupMethodSelected === undefined){
            this.setState({
                validated: "error"
            });
            return;
        }
        const { fixedFee, variableFee } = topupMethodSelected!;

        let fee = 0;
        let actualTopupAmount = 0;
        let validated = this.state.topupFieldValid;
        let e = ""
        let bNew = currentBalance;
        let topupAmountRequestedNum = 0;

        let topupAmountRequestedConv = topupAmountRequested.replace(",", ".");

        if (topupAmountRequestedConv == undefined ||
                topupAmountRequestedConv == "" ||
                isNaN(parseFloat(topupAmountRequestedConv))) {
            validated = false;
            e = t("berta:topup.errors.noValue");
        } else {
            topupAmountRequestedNum = Number(topupAmountRequestedConv);
            if (topupAmountRequestedNum < minDeposit) {
                validated = false;
                e = t("berta:topup.errors.tooLittle") + minDeposit.toFixed(2) + " EUR"; 
            }
            if (topupAmountRequestedNum > (maxBalance - currentBalance)) {
                validated = false
                e = t("berta:topup.errors.tooMuch") + maxBalance.toFixed(2) + " EUR";
            }
            if (topupAmountRequestedNum < 0) {
                validated = false;
                e = t("berta.topup.errors.other")
            }

            fee = fixedFee + variableFee * topupAmountRequestedNum;
            if (fee > topupAmountRequestedNum)
                fee = topupAmountRequestedNum;
            
            actualTopupAmount = topupAmountRequestedNum - fee;
            bNew += actualTopupAmount;
        }

        this.setState({ 
            validated: validated,
            errorMessage: e,
            topupFee: fee, 
            topupAmountActual: actualTopupAmount,
            balanceNew: bNew,
            topupAmountRequestedNum: topupAmountRequestedNum
        });
    }

    onPayClick = () => {
        const { depositAPI, onAlert } = this.props;
        const { topupAmountRequestedNum, topupMethodSelected } = this.state;

        this.setState( {buttonLoading : true} );

        depositAPI.createStripePayment(topupAmountRequestedNum, topupMethodSelected!.name).then((result) => {
            location.href = result.data!.url;
        }).catch((error) => {
            onAlert(`Could not create payment: ${JSON.stringify(error)}`, "danger");
            this.setState( {buttonLoading : false} );
        }); 
    }

    onPaymentMethodSelectionClick = () => {
        this.setState({ topupMethodSelectionOpen: !this.state.topupMethodSelectionOpen })
    }

    onPaymentMethodSelect = (_event, value) => {
        const { topupMethods } = this.state;

        const topupMethod = topupMethods.find((f) => {
            return f.name == value;
        })
        if (topupMethod === undefined) {
            this.setState({
                validated: "error"
            })
            return;
        }

        this.setState({ 
            topupMethodSelected: topupMethod,
            topupMethodSelectionOpen: false 
        })
        this.checkValueAndComputeFee();
    }

    render() {
        const { isOpen, t, onClose } = this.props;
        const { topupAmountActual, topupAmountRequested, topupFee, topupMethods, validated, balanceNew, isLoading,
            topupMethodSelected, topupMethodSelectionOpen, buttonLoading, errorMessage } = this.state;

        const topupMethodSelects = topupMethods.map((val, i) => { return (<SelectOption value={val.name.toString()} key={i}>{val.name}</SelectOption> )});

        const paymentMethodToggle = (toggleRef) => (
            <MenuToggle
                ref={toggleRef}
                onClick={this.onPaymentMethodSelectionClick}
                isExpanded={topupMethodSelectionOpen}
                style={{ width: '200px' }}
            >
                {topupMethodSelected ? topupMethodSelected.name : ""}
            </MenuToggle>
        )

        const errorElement = (
            <Alert variant="danger" title={errorMessage} isInline={true} style={{marginBottom: "1.5em"}} />
        )

        return (
            <React.Fragment>
                <Modal
                    variant={ModalVariant.medium}
                    title={t("berta:topup.dialogTitle", "Top-up Account")}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    {errorMessage != "" && errorElement}
                    <TextContent>
                        <Text id='topup-header' component={TextVariants.h3}>{t("berta:topup.online")}</Text>
                        <Text id='topup-info' component={TextVariants.p}>
                            {t("berta:topup.hint")}
                        </Text>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.onPayClick();
                            }}
                            isHorizontal
                        >
                            <FormGroup
                                label="Zahlungsweise"
                                fieldId='type'
                            >
                                <Select
                                    toggle={paymentMethodToggle}
                                    selected={topupMethodSelected ? topupMethodSelected.name : ""}
                                    onSelect={this.onPaymentMethodSelect}
                                    isOpen={topupMethodSelectionOpen}
                                >
                                    {topupMethodSelects}
                                </Select>
                                <TextContent>
                                    {topupMethodSelected ? topupMethodSelected.note : ""}
                                </TextContent>
                            </FormGroup>
                            <ValidatableInputField
                                title={t("berta:topup.requestAmount")}
                                inputId='topupId'
                                validateFor={ValidationOptions.TwoDigitFixedPoint}
                                value={topupAmountRequested}
                                onChange={(value, valid) => {
                                    this.checkValueAndComputeFee();
                                    this.setState({
                                        topupAmountRequested: value,
                                        topupFieldValid: valid
                                    })
                                }}
                            />
                            <FormGroup label={t("berta:topup.fee")} fieldId='fee'>
                                <TextInput isDisabled id='topup-fee' value={topupFee.toFixed(2) + " €"} />
                            </FormGroup>
                            <FormGroup label={t("berta:topup.topupActual")} fieldId='remainder'>
                                <TextInput isDisabled id='topup-actual' value={topupAmountActual.toFixed(2) + " €"} />
                            </FormGroup>
                            <FormGroup label={t("berta:topup.newBalance")} fieldId='remainder'>
                                <TextInput isDisabled id='balance-new' value={balanceNew.toFixed(2) + " €"} />
                            </FormGroup>
                            <ActionGroup>
                                <Button variant="primary" isDisabled={!validated || isLoading} isLoading={buttonLoading} onClick={this.onPayClick}>{t("berta:topup.payButton")}</Button>
                            </ActionGroup>
                        </Form>
                    </TextContent>
                </Modal>
            </React.Fragment>
        );
    }
}

export const TopupDialog = withTranslation()(TopupDialogImpl);