import { encodeQueryData, RemoteConnection } from "@app/RemoteConnection";
import { TableFilterQuery, TableRowData } from "@app/model/IDBHelpers";
import { IRfidAPI, IRFIDInfo, IRFIDRegisterCode } from "@app/model/IRfidAPI";



export class RfidAPI implements IRfidAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchRFIDs(query: TableFilterQuery, uid?: number, fetchFullName?: boolean) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter,
            "uid": uid,
            "fetchFullName": fetchFullName
        });
        return this._connection.getPromise<TableRowData<IRFIDInfo>, void>(`rfids?${args}`);
    }

    public removeRFID(id: number) {
        return this._connection.postPromise<boolean, void>(`rfid/delete`, id);
    }

    public fetchRFIDRegisterCodes(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
        return this._connection.getPromise<TableRowData<IRFIDRegisterCode>, void>(`rfid_register_codes?${args}`);
    }

    public deleteRFIDRegisterCode(id: number) {
        return this._connection.postPromise<boolean, void>(`rfid/delete_register_code`, id);
    }

    public postRFIDCode(uid: number, register_code: string, comment: string) {
        const data = {
            uid: uid,
            register_code: register_code,
            comment: comment
        };
        return this._connection.postPromise<boolean, void>(`rfid/registerRFID?uid=${uid}`, data);
    }
}