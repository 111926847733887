import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Modal,
    ModalVariant,
    TextContent,
    Text,
    TextVariants,
    Button,
    Form,
    FormGroup,
    TextInput,
    Alert,
} from '@patternfly/react-core';
import { IRfidAPI } from '@app/model/IRfidAPI';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';


export interface RFIDAddDialogProps extends WithTranslation {
    isOpen: boolean,
    onClose: () => void,
    onSubmitted: () => void,
    rfidAPI: IRfidAPI,
    uid: number,
}

class RFIDAddDialogImpl extends React.Component<RFIDAddDialogProps> {
    public state = {
        register_code: "",
        comment: "",
        errorText: ""
    }

    registerCode() {
        const { onSubmitted, rfidAPI, uid } = this.props;
        const { register_code, comment } = this.state;


        rfidAPI.postRFIDCode(uid, register_code, comment).then(value => {
            if (value.data == true) {
                this.setState({ register_code: "", comment: "", errorText: undefined });
                onSubmitted();
            }
            else {
                this.setState({ errorText: "berta:rfids.dialog.invalidCode" });
            }
        }).catch(error => {
            this.setState({ errorText: JSON.stringify(error) });
        });
    }

    render() {
        const { isOpen, t, onClose } = this.props;
        const { register_code, comment, errorText } = this.state;

        let errorElement = undefined as (undefined | JSX.Element);
        if (errorText) {
            errorElement = (<Alert variant="danger" title={t(errorText)} style={{ marginBottom: "1.5em" }} />);
        }

        return (
            <Modal
                variant={ModalVariant.small}
                title={t("berta:user.rfidDialog.title", "Link new RFID Card")}
                isOpen={isOpen}
                onClose={() => {
                    this.setState({ register_code: "", errorText: "" });
                    onClose();
                }}
                actions={[
                    <Button key="confirm" variant="primary"
                        onClick={() => {
                            this.registerCode();
                        }}>{t("berta:rfids.dialog.linkCode")}</Button>
                ]}
            >
                {errorElement}
                <TextContent>
                    <Text component={TextVariants.p}>
                        {t("berta:rfids.dialog.hint")}
                    </Text>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.registerCode();
                        }}>
                        <ValidatableInputField
                            title={t("berta:rfids.dialog.code")}
                            inputId="register_code"
                            value={register_code}
                            onChange={(value, valid) => {
                                this.setState({
                                    register_code: value
                                })
                            }}
                            validateFor={ValidationOptions.RFIDRegisterCode}
                        />
                            
                        {/* <FormGroup label="Registrierungs Code" fieldId='register_code'>
                            <TextInput value={register_code}
                                onChange={value => {
                                    this.setState({ register_code: value });
                                }}
                            />
                        </FormGroup> */}
                        <FormGroup label={t("berta:rfids.dialog.comment")} fieldId='comment'>
                            <TextInput value={comment}
                                onChange={(_e, value) => {
                                    this.setState({ comment: value });
                                }}
                            />
                        </FormGroup>
                    </Form>
                </TextContent>
            </Modal>
        );
    }
}

export const RFIDAddDialog = withTranslation()(RFIDAddDialogImpl);